import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import ResultModelBmx from "../../casino_components/ResultModelBmx/ResultModelBmx";
import RoundedTabBmx from "../../casino_components/RoundedTabBmx/RoundedTabBmx";
import PageHeaderTheme from "../../casino_components/PageHeaderTheme/PageHeaderTheme";
import Loader from "../../components/Loader/Loader";
import BetLocked from "../../components/BetLocked/BetLocked";
import MyBetTabBmx from "../../casino_components/MyBetTabBmx/MyBetTabBmx";
import BetListTableBmx from "../../casino_components/BetListTableBmx/BetListTableBmx";
import { httpPost, httpPostBet } from "../../_helpers/http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from '../../components/ErrorTost/ErrorTost';
import { userActions } from "../../_actions";
import { betChipsData } from '../../_config';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';


class TeenpattiOneDayTheme2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backBetModal: false,
            offset: 0,
            betSlipData: {},
            fieldsbet: {},
            errorsbet: {},
            count: 0,
            time: 7,
            casinoData: [],
            ResultModel: false,
            backBetModalMobile: false,
            showLoader: false,
            result: {},
            casinoDetails: {},
            fetchData: "",
            socketURL: "",
            tvUrl: "",
            cacheURL: "",
            betStatus: "",
            cashinoStatus: "",
            eventId: "",
            minStake: "",
            maxStake: "",
            name: "",
            shortName: "Teen",
            betList: [],
            betresponse: [],
            setting: {},
            posArray: {},
            currentRoundId: 0,
            oddsDifference: 0,
            totalProfitLoss: 0,
            clicked: true,
            completeBetList: [],
            inCompleteBetList: [],
            betChipsData: [],
            socketPerm: "",
            domainSettingByDomainName: null,

        };
        this.socket = null;
        this.section1Ref = React.createRef();
        this.scrollTimeout = null;
        this.inputRef = React.createRef();
    }

    checkWindowWidth() {
        const isMobile = window.matchMedia('(max-width: 100vw)').matches;

        if (isMobile && this.inputRef.current) {
            setTimeout(() => {
                this.inputRef.current.focus();
            }, 0);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.users && nextProps.users.betPlaceSucess) {
            return {
                ...nextProps,
                backBetModal: false,
                backBetModalMobile: false,
                betSlipData: {},
            }
        } else {
            return {
                ...nextProps,

            }
        }
    }
    increaseCount = () => {
        const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
        this.setState({ count: parseFloat(increasedCount) });
    };

    decreaseCount = () => {
        const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
        this.setState({ count: parseFloat(decreasedCount) });
    };

    handleBackOpen = (data, sectionRef) => {
        try {
            this.setState({
                backBetModal: false,
            });

            if (this.scrollTimeout) {
                clearInterval(this.scrollTimeout);
            }

            this.setState({
                backBetModal: true,
                backBetModalMobile: true,
                betSlipData: { ...data, stake: "0" },
                count: data.odds,
                time: 7,
            });

            this.scrollTimeout = setInterval(() => {
                this.setState(prevState => ({ time: prevState.time - 1 }), () => {
                    if (this.state.time === 0) {
                        clearInterval(this.scrollTimeout);
                        this.setState({ backBetModal: false });
                    }
                });
            }, 1000);

            setTimeout(() => {
                if (sectionRef && sectionRef.current) {
                    sectionRef.current.scrollIntoView({ behavior: 'smooth' });

                    if (window.innerWidth <= 768 && this.inputRef.current && sectionRef && sectionRef.current) {
                        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
                        setTimeout(() => {
                            this.inputRef.current.focus();
                        }, 0);
                    }
                }
            }, 0);
        } catch (error) {
            console.error('Error handling back open:', error);
        }
    };

    updateStackOnclick = (num) => {
        let { betSlipData } = this.state;
        betSlipData.stake = num;
        this.setState({
            betSlipData: betSlipData,
        });
    };

    componentDidMount() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.getDomainSettingByDomainName();
        let requestData = {
            eventId: this.props.match.params.eventId,
        }
        this.getDidMountData(requestData)
        this.betChipData()
        if (this.inputRef.current) {
            this.inputRef.current.addEventListener('touchstart', this.handleTouchStart);
        }
        if (window.innerWidth <= 768 && this.inputRef.current) {
            this.inputRef.current.focus();
            this.inputRef.current.dispatchEvent(new Event('touchstart'));
        }
    }

    getDomainSettingByDomainName = () => {
        try {
          let domainSetting = localStorage.getItem('domainSettingByDomainName');
          if (domainSetting) {
            const parsedDomainSetting = JSON.parse(domainSetting);
            this.setState({ domainSettingByDomainName: parsedDomainSetting });
          } else {
            this.setState({ domainSettingByDomainName: null });
          }
        } catch {
          console.error('Error parsing domainSettingByDomainName:');
          this.setState({ domainSettingByDomainName: null });
        }
      };


    updateStake = (event) => {
        try {
            let { value } = event.target;
            let { betSlipData } = this.state;
            if (value.startsWith('0') && value.length > 1) {
                value = value.slice(1);
            }
            betSlipData.stake = value;
            this.setState({
                betSlipData: betSlipData,
            });
        } catch (error) {
            console.error('Error updating stake:', error);
        }
    };

    betChipData = () => {
        try {
            let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
            const myArray = betChipsDatas && Object.keys(betChipsDatas).length > 0 ? Object.values(betChipsDatas) : Object.values(betChipsData);
            this.setState({ betChipsData: myArray });
        } catch (error) {
            console.error('Error parsing JSON from localStorage: betChipsDatas', error);
            this.setState({ betChipsData: betChipsData });
        }
    };

    getDidMountData = async (requestData) => {
        this.setState({ showLoader: true })
        let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
        if (!getCasinoDetails.error) {
            let casinoDetails = getCasinoDetails.data
            this.setState({
                casinoDetails: casinoDetails,
                fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
                socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
                tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
                cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
                socketPerm: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : "",
                betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
                cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
                eventId: casinoDetails && casinoDetails.eventId ? casinoDetails.eventId : "",
                minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
                maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
                name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
                shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "Teen",
                setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
                oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
            })
            if (casinoDetails.eventId) {
                this.betList(casinoDetails.eventId)
            }

            let socketPerm = ""
            let socketUrl = ""
            let cacheUrl = ""
            if (casinoDetails.fetchData) {
                socketPerm = casinoDetails.fetchData
            }
            if (casinoDetails.socketURL) {
                socketUrl = casinoDetails.socketURL
            }
            if (casinoDetails.cacheURL) {
                cacheUrl = casinoDetails.cacheURL
            }

            if (socketPerm === "socket") {
                await this.connectSocket(socketUrl, socketPerm, casinoDetails.shortName)
            } else {
                await this.callCache(cacheUrl);
            }
        } else {

        }
        this.setState({ showLoader: false })
    }

    connectSocket = async (socketUrl, socketPerm, shortName) => {
        if (!socketUrl) {
            socketUrl = this.state.socketURL
        }
        if (!socketPerm) {
            socketPerm = this.state.socketPerm
        }
        if (!shortName) {
            shortName = this.state.shortName
        }
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            return;
        }

        if (socketPerm === "socket") {
            this.socket = io.connect(socketUrl, {
                transports: ["websocket"],
                reconnection: false,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 99,
            });

            this.socket.emit('JoinRoom', shortName);
            this.socket.on(shortName, data => {
                this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
                this.setState({ casinoData: data })
                this.updatePos()
            });
        }
    }

    callCache = async (cacheUrl) => {
        try {
            this.interval = setInterval(async () => this.getMarketCacheUrl(cacheUrl), 1000);
        } catch (error) {
            console.error('Error calling cache:', error);
        }
    }

    getMarketCacheUrl = async (cacheUrl) => {
        try {
            const response = await axios.get(cacheUrl);
            this.setState({ casinoData: response?.data?.data })
        } catch (error) {
            console.error('Error fetching cache data:', error);
        }
    }

    updatePos = async () => {
        const { betList, currentRoundId } = this.state;
        if (betList && betList.length > 0) {
            const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
            let pushPos = {};
            filteredBets.forEach((bet) => {
                const posArray = bet.posArray;
                Object.entries(posArray).forEach(([key, value]) => {
                    pushPos[key] = (pushPos[key] || 0) + Number(value);
                });
            });

            this.setState({ posArray: pushPos });
        }
    }


    betList = async (eventId) => {
        let betReq = {
            "eventId": eventId,
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
        };

        let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
        let totalProfitLoss = 0
        let betList = getCasinoDetails?.data?.casinoBetData

        if (betList && betList.length > 0) {
            betList.map((bet, key) => {
                let profitLoss = "Not Declare"
                let profitLossCount = 0
                if (bet.isDeclare) {
                    profitLossCount = bet.profitLoss ? bet.profitLoss : 0
                    profitLoss = bet.profitLoss
                }
                totalProfitLoss += Number(profitLossCount)
                betList[key].profitLoss = profitLoss
            })
        }
        if (getCasinoDetails) {
            this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
        }
    }

    componentWillUnmount() {
        clearInterval(this.cacheInterval);
        clearTimeout(this.timer);
        if (this.inputRef.current) {
            this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
        }
        this.cleanupWebSocket();
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    cleanupWebSocket = () => {
        if (this.socket) {
            this.socket.close();
        }
    };

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' && !this.state.isConnected) {
            this.connectSocket()
        } else if (document.visibilityState === 'hidden') {
            this.cleanupWebSocket();
        }
    };

    handleResultModel = (data) => {
        this.setState({ ResultModel: true, result: data });

    };

    updateStackOnclic = (num) => {
        let { betSlipData } = this.state;
        betSlipData.stake = num;
        this.setState({
            betSlipData: betSlipData,
        });
    };

    placeBet = async () => {
        const { casinoData } = this.state;
        this.setState({ LoadingBet: true })
        let { data } = casinoData ? casinoData : {};
        let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
        let betObject = {
            "roundId": t1.mid,
            "sid": this.state.betSlipData.data.sid,
            "rate": this.state.count + "",
            "amount": Number(this.state.betSlipData.stake),
            "casinoType": this.state.shortName ? this.state.shortName : "Teen",
            "eventId": this.props.match.params.eventId,
            "betFor": this.state.betSlipData.data.nat + "",
            "isLay": this.state.betSlipData.type === "No" ? true : false,
        }

        const result = await httpPostBet("casino/casinoBetPlace", betObject);
        if (result) {
            if (result.error === false) {

                this.setState(
                    {
                        betresponse: result.data
                    },
                );
                toast.success(<ErrorTost message={result.message} />);
                this.betList(this.props.match.params.eventId)
                this.props.dispatch(userActions.getUserBalance());
            }
            else {
                toast.error(<ErrorTost message={result.message} />);
            }
        }
        this.handleClose();
        this.setState({ LoadingBet: false })
    };


    inputChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        if (value < 0 || isNaN(Number(value))) {
            value = 0;
        }

        if (value[0] === '0' && value.length > 1) {
            value = value.substring(1);
        }

        let { betSlipData } = this.state;
        betSlipData.stake = value;
        this.setState({
            betSlipData: betSlipData,
            [name]: Number(value),
        });
    };

    handleClose = () => {
        this.setState({ backBetModal: false, backBetModalMobile: false });
    };

    handleCloseResult = () => {
        this.setState({ ResultModel: false, });
    };

    render() {
        // let clientNotification = JSON.parse(localStorage.getItem('notification'))
        const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, totalProfitLoss, clicked, minStake, maxStake, betChipsData ,domainSettingByDomainName} = this.state;
        let { data, result } = casinoData ? casinoData : {};
        let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
        let t2 = data && data.t1 && data.t1[1] ? data.t1[1] : {};
        let PlayerA = data && data.t1 && data.t1[0] ? data.t1[0] : {};
        let PlayerB = data && data.t1 && data.t1[1] ? data.t1[1] : {};
        return (
            <>
                {showLoader ?
                    <Loader active={showLoader === true} />
                    :
                    <div className={`w-[100vw] relative h-screen page_bg overflow-y-auto text-sm flex ${domainSettingByDomainName && domainSettingByDomainName.clientNotification  ? "md:pt-[105px] pt-[100px]" : "md:pt-[72px] pt-[70px]"}`}>
                        {ResultModel &&
                            ResultModel === true ? (
                            <ResultModelBmx
                                handleClose={this.handleCloseResult}
                                name={name ? name : "20-20 Teenpatti"}
                                shortName={shortName ? shortName : "teen20"}
                                result={this.state.result}
                            />
                        ) : null}


                        <div className="lg:flex w-full p-1.5 space-x-1.5  ">
                            <div className="lg:w-[41%] md:w-full mx-auto space-y-2">
                                <div>
                                    <PageHeaderTheme
                                        PageTitle={name ? name : "20-20 Teenpatti"}
                                        ruleImage={"/rulesImage/tp-rules.webp"}
                                        t1={t1}
                                    />

                                    {clicked ?
                                        <div className="bg-black flex justify-between w-full relative xl:h-[400px] md:h-[350px] h-[250px]">
                                            <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                                            <div className=" flex justify-between">
                                                <div className="absolute top-0 left-0 w-full">
                                                    <div className="flex justify-between items-center w-full p-1">
                                                        <div>
                                                            <div className="flex space-x-2 justify-start">
                                                                <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                                <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                                <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                            </div>
                                                            <div className="text-white font-semibold p-[1px] tracking-wide bg-black/80 text-[12px]">Player A</div>
                                                        </div>

                                                        <div>
                                                            <div className="flex space-x-2 justify-start">
                                                                <img src={`/cards/${t2 && t2.C1 ? t2.C1 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                                <img src={`/cards/${t2 && t2.C2 ? t2.C2 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                                <img src={`/cards/${t2 && t2.C3 ? t2.C3 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                            </div>
                                                            <div className="text-white font-semibold p-[1px] tracking-wide bg-black/80 text-[12px]">Player B</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex justify-end items-end absolute md:bottom-2 bottom-4 right-0 ">
                                                    <FlipCountdown
                                                        titlePosition='hidden'
                                                        hideYear
                                                        hideMonth
                                                        hideDay
                                                        hideHour
                                                        hideMinute
                                                        endAtZero
                                                        size='small'
                                                        endAt={new Date(Date.now() + 1000 * (t1 && t1.lasttime ? t1.lasttime : null)).toUTCString()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="bg-black flex justify-between w-full relative h-[0px]">
                                            <div className="absolute top-[10px] left-0 w-full">
                                                <div className="flex justify-between items-center w-full p-1">
                                                    <div>
                                                        <div className="flex space-x-2 justify-start">
                                                            <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                            <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                            <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                        </div>
                                                        <div className="text-white font-semibold p-[1px] tracking-wide bg-black/80 text-[12px]">Player A</div>
                                                    </div>

                                                    <div>
                                                        <div className="flex space-x-2 justify-start">
                                                            <img src={`/cards/${t2 && t2.C1 ? t2.C1 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                            <img src={`/cards/${t2 && t2.C2 ? t2.C2 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                            <img src={`/cards/${t2 && t2.C3 ? t2.C3 : 1}.png`} alt="card" className="h-8 w-7 rounded-sm border-[1px] border-yellow-300" />
                                                        </div>
                                                        <div className="text-white font-semibold p-[1px] tracking-wide bg-black/80 text-[12px]">Player B</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>

                                <div className="border border-gray-300 grey_background white-text divide-y divide-white">
                                    <div className="grid grid-cols-4 text-center black-text">
                                        <div className="col-span-2 detail-header-main w-full font-semibold text-[12px] flex justify-start white-text px-[6PX] items-center py-[6px]">
                                            MIN: {minStake ? minStake : "100"} &nbsp;
                                            MAX: {maxStake ? maxStake : "10000"}</div>
                                        <div class="w-full font-semibold text-[14px] flex justify-center items-center odds-bet-lgaai py-[6px] uppercase">Lagai</div>
                                        <div class="w-full font-semibold text-[14px] flex justify-center items-center odds-bet-khaii py-[6px] uppercase">khai</div>
                                    </div>
                                    <div className="w-full text-center colour_back_odds">
                                        <div className="grid grid-cols-4">
                                            <div className="h-full text-[14px] capitalize text-gray-800 font-semibold py-2 px-2 col-span-2 w-full flex justify-between items-center">
                                                <div>{PlayerA && PlayerA.nat ? PlayerA.nat : "Player A"}</div>
                                                <div className={`${posArray[PlayerA.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[PlayerA.sid]} </div>
                                            </div>
                                            <div class="flex justify-end items-center divide-x w-full">
                                                {PlayerA && PlayerA.gstatus === 'ACTIVE' && PlayerA.b1 != 0 ?
                                                    <div className="w-full odds-bet-lgaai cursor-pointer " onClick={() => this.handleBackOpen({ data: PlayerA, type: "Yes", odds: PlayerA.b1, nat: PlayerA.clientNotification }, this.section1Ref)}>
                                                        <div className=" text-center py-2" >
                                                            <span className="text-[16px] text-black font-medium">{PlayerA && PlayerA.b1 ? (PlayerA.b1 / 100).toFixed(2) : "-"}</span><br />
                                                        </div>
                                                    </div> :
                                                    <div className="w-full odds-bet-lgaai cursor-pointer " >
                                                        <div className=" text-center py-2 relative lagai-background text-black">
                                                            {/* <span className='bg-white/50 px-1.5 py-1 border-[1px] border-black uppercase'>suspended</span> */}
                                                            <span className="text-[18px] font-semibold">{PlayerA && PlayerA.b1 ? (PlayerA.b1) : "-"}</span><br />
                                                            <BetLocked />
                                                        </div>
                                                    </div>}
                                            </div>
                                            <div class="flex justify-end items-center divide-x w-full">
                                                {PlayerA && PlayerA.gstatus === 'ACTIVE' && PlayerA.l1 != 0 ?
                                                    <div className="w-full odds-bet-khaii cursor-pointer " onClick={() => this.handleBackOpen({ data: PlayerA, odds: PlayerA.l1, nat: PlayerA.nat, type: "No" }, this.section1Ref)}>
                                                        <div className=" text-center py-2" >
                                                            <span className="text-[16px] text-black font-medium">{PlayerA && PlayerA.l1 ? (PlayerA.l1 / 100).toFixed(2) : "-"}</span><br />
                                                        </div>
                                                    </div> :
                                                    <div className="w-full odds-bet-khaii cursor-pointer " >
                                                        <div className=" text-center py-2 relative khai-background text-black">
                                                            {/* <span className='bg-white/50 px-1.5 py-1 border-[1px] border-black uppercase'>suspended</span> */}
                                                            <span className="text-[18px] font-semibold">{PlayerA && PlayerA.l1 ? (PlayerA.l1) : "-"}</span><br />
                                                            <BetLocked />
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full text-center colour_back_odds">
                                        <div className="grid grid-cols-4">
                                            <div className="h-full text-[14px] capitalize text-gray-800 font-semibold py-2 px-2 col-span-2 w-full flex justify-between items-center">
                                                <div>{PlayerB && PlayerB.nat ? PlayerB.nat : "Player B"} </div>
                                                <div className={`${posArray[PlayerB.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[PlayerB.sid]} </div>
                                            </div>
                                            <div class="flex justify-end items-center divide-x w-full">
                                                {PlayerB && PlayerB.gstatus === 'ACTIVE' && PlayerB.b1 !== "0.00" ?
                                                    <div className="w-full odds-bet-lgaai cursor-pointer " onClick={() => this.handleBackOpen({ data: PlayerB, type: "Yes", odds: PlayerB.b1, nat: PlayerB.nat }, this.section1Ref)}>
                                                        <div className=" text-center py-2" >
                                                            <span className="text-[16px] text-black font-medium">{PlayerB && PlayerB.b1 ? (PlayerB.b1 / 100).toFixed(2) : "-"}</span><br />
                                                        </div>
                                                    </div> :
                                                    <div className="w-full odds-bet-lgaai cursor-pointer " >
                                                        <div className=" text-center py-2 relative lagai-background text-black">
                                                            {/* <span className='bg-white/50 px-1.5 py-1 border-[1px] border-black uppercase'>suspended</span> */}
                                                            <span className="text-[18px] font-semibold">{PlayerB && PlayerB.b1 ? (PlayerB.b1) : "-"}</span><br />
                                                            <BetLocked />
                                                        </div>
                                                    </div>}
                                            </div>
                                            <div class="flex justify-end items-center divide-x w-full">
                                                {PlayerB && PlayerB.gstatus === 'ACTIVE' && PlayerB.l1 !== "0.00" ?
                                                    <div className="w-full odds-bet-khaii cursor-pointer " onClick={() => this.handleBackOpen({ data: PlayerB, odds: PlayerB.l1, nat: PlayerB.nat, type: "No" }, this.section1Ref)}>
                                                        <div className=" text-center py-2" >
                                                            <span className="text-[16px] text-black font-medium">{PlayerB && PlayerB.l1 ? (PlayerB.l1 / 100).toFixed(2) : "-"}</span><br />
                                                        </div>
                                                    </div> :
                                                    <div className="w-full odds-bet-khaii cursor-pointer " >
                                                        <div className=" text-center py-2 relative khai-background text-black">
                                                            {/* <span className='bg-white/50 px-1.5 py-1 border-[1px] border-black uppercase'>suspended</span> */}
                                                            <span className="text-[18px] font-semibold">{PlayerB && PlayerB.l1 ? (PlayerB.l1) : "-"}</span><br />
                                                            <BetLocked />
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {LoadingBet === true ?
                                    <div className='border-[2px] border-[#C99D1E] bg-[#f9f9f9] p-[5px]  lg:block hidden rounded-[5px]' >
                                        <div className="">
                                            <div className='flex justify-between items-center'>
                                                <div className="text-black text-[20px] font-bold text-center w-full whitespace-nowrap">{this.state.betSlipData && this.state.betSlipData.nat ? this.state.betSlipData.nat : null} :</div>
                                                <span className='w-full text-left'>
                                                    RATE : {this.state.count}
                                                </span>
                                                <div className='bg-black rounded-full white-text p-2'>
                                                    <CountdownCircleTimer
                                                        isPlaying
                                                        duration={7}
                                                        colors={['#000000']}
                                                        colorsTime={[7]}
                                                        size={20}
                                                        strokeWidth={0}
                                                        className="bg-white p-0"
                                                    >
                                                        {({ remainingTime }) => remainingTime === 7 ? time : time}

                                                    </CountdownCircleTimer>
                                                </div>
                                            </div>

                                            <div className='flex justify-center items-center space-x-2'><span>AMOUNT</span>
                                                <input ref={this.inputRef} type="number" className="px-1 w-24 border-2 border-black" placeholder="0" />
                                                <div className='relative flex justify-center'>
                                                    <button className="bg-[#3A61A2] px-3 py-1 uppercase rounded-full text-white text-[13px] tracking-widest flex justify-center items-center">Done
                                                        <button className="w-full white-text px-3 py-2 h-4 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                                            <div className=" flex items-center justify-center absolute bg-transparent">
                                                                <div className="flex items-center justify-center bg-transparent w-full">
                                                                    <div className="w-5 h-5 rounded-full animate-spin 
                          border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <div className='row '>
                                                {betChipsData && betChipsData.length > 0 ? betChipsData.map((element, index) => (
                                                    <div key={index} className="col-lg-3 col-md-2 col-4 py-2 ">
                                                        <span onClick={() => this.updateStackOnclick(element)} className='bg-[#61ACDE] p-1.5 px-4 text-white md:font-bold font-medium rounded-md cursor-pointer' key={index}>{element}</span>
                                                    </div>
                                                )) : null}

                                            </div>
                                        </div>
                                        <div className='flex justify-between items-center space-x-1'>
                                            <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" >Clear Input</button>
                                            <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.handleClose()}>Clear Close</button>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {backBetModal === true ?
                                            <div className='py-1' ref={this.section1Ref} id="section1" >
                                                <div className='border-[2px] border-[#C99D1E] bg-[#f9f9f9] p-[5px]  lg:block hidden rounded-[5px]' >
                                                    <div className="">
                                                        <div className='flex justify-between items-center'>
                                                            <div className="text-black text-[20px] font-bold text-center w-full whitespace-nowrap">{this.state.betSlipData && this.state.betSlipData.nat ? this.state.betSlipData.nat : null} :</div>
                                                            <span className='w-full text-left'>
                                                                RATE : {this.state.count}
                                                            </span>
                                                            <div className='bg-black rounded-full white-text p-2'>
                                                                <CountdownCircleTimer
                                                                    isPlaying
                                                                    duration={7}
                                                                    colors={['#000000']}
                                                                    colorsTime={[7]}
                                                                    size={20}
                                                                    strokeWidth={0}
                                                                    className="bg-white p-0"
                                                                >
                                                                    {({ remainingTime }) => remainingTime === 7 ? 7 : time}

                                                                </CountdownCircleTimer>
                                                            </div>
                                                        </div>

                                                        <div className='flex justify-center items-center space-x-2'><span>AMOUNT</span>
                                                            <input ref={this.inputRef} type="number" className="px-1 w-24 border-2 border-black" placeholder="0" value={this.state.betSlipData.stake} onChange={this.updateStake} />
                                                            <div className=' flex justify-center'>
                                                                <button onClick={() => this.placeBet()} className="bg-[#3A61A2] px-5 py-1 uppercase rounded-full text-white text-[13px] tracking-widest">Done
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='flex justify-center items-center'>
                                                        <div className='row '>
                                                            {betChipsData && betChipsData.length > 0 ? betChipsData.map((element, index) => (
                                                                <div key={index} className="col-lg-3 col-md-2 col-4 py-2 ">
                                                                    <span onClick={() => this.updateStackOnclick(element)} className='bg-[#61ACDE] p-1.5 px-4 text-white md:font-bold font-medium rounded-md cursor-pointer' key={index}>{element}</span>
                                                                </div>
                                                            )) : null}

                                                        </div>
                                                    </div>
                                                    <div className='flex justify-between items-center space-x-1'>
                                                        <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.updateStackOnclick("0")}>Clear Input</button>
                                                        <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.handleClose()}>Clear Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </>}

                                {LoadingBet === true ?
                                    <>
                                        <div className='block lg:hidden bg-[#E9E9E9]'>
                                            <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                                                <div className='text-sm text-black/30 font-bold pl-2'>Amount</div>
                                                <div className='border-[1px] border-gray-400 w-1/2'>
                                                    <input type="number" className=" w-full text-black/30 bg-[#E9E9E9] focus:outline-none pointer-events-none px-1 text-base " placeholder="0" value="0" />

                                                </div>
                                                <div className='flex justify-end items-center'>
                                                    <div className='bg-black white-text px-2'>
                                                        <CountdownCircleTimer
                                                            isPlaying
                                                            duration={7}
                                                            colors={['#000000']}
                                                            colorsTime={[7]}
                                                            size={20}
                                                            strokeWidth={0}
                                                            className="bg-white p-0"

                                                        >
                                                            {({ remainingTime }) => remainingTime === 7 ? time : time}
                                                        </CountdownCircleTimer>
                                                    </div>
                                                    <div className='relative flex justify-center px-2'>
                                                        <button className="placebet-button-disable px-2 py-1 uppercase rounded white-text text-[13px] disabled font-bold flex justify-center items-center">Done
                                                            <button className="w-full white-text px-3 py-2 h-4 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                                                <div className=" flex items-center justify-center absolute bg-transparent">
                                                                    <div className="flex items-center justify-center bg-transparent w-full">
                                                                        <div className="w-5 h-5 rounded-full animate-spin 
                          border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {backBetModal === true ?
                                            <div ref={this.section1Ref} id="section1">
                                                <div className='lg:hidden block bg-[#E9E9E9]' >
                                                    <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                                                        <div className='text-sm font-bold pl-2'>Amount</div>
                                                        <div className='border-[1px] border-gray-400 w-1/2'>
                                                            <input ref={this.inputRef} type="text" autoFocus  // or type="text"
                                                                autoCapitalize="none" className=" w-full black-text bg-[#E9E9E9] text-base focus:outline-none cursor-pointer placeholder-black px-1" placeholder="0" name="stake" onChange={this.inputChange} list="stake" inputMode="numeric" />
                                                            <datalist id="stake" className='bg-black white-text'>
                                                                {betChipsData.map((element, index) => (
                                                                    <option key={index} value={element ? element : 0} />
                                                                ))}
                                                            </datalist>
                                                        </div>

                                                        <div className='flex justify-end items-center text-base'>
                                                            <div className='bg-black white-text px-2'>
                                                                <CountdownCircleTimer
                                                                    isPlaying
                                                                    duration={7}
                                                                    colors={['#000000']}
                                                                    colorsTime={[7]}
                                                                    size={20}
                                                                    strokeWidth={0}
                                                                    className="bg-white p-0"

                                                                >
                                                                    {({ remainingTime }) => remainingTime === 7 ? 7 : time}
                                                                </CountdownCircleTimer>
                                                            </div>
                                                            <div className=' flex justify-center px-2'>
                                                                <button onClick={() => this.placeBet()} className="placebet-button px-2 py-1 uppercase rounded white-text font-bold text-[13px] cursor-pointer">Done</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : <div className='block lg:hidden bg-[#E9E9E9]'>
                                                <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                                                    <div className='text-sm text-black/30 font-bold pl-2'>Amount</div>
                                                    <div className='border-[1px] border-gray-400 w-1/2'>
                                                        <input type="number" className=" w-full text-black/30 bg-[#E9E9E9] focus:outline-none pointer-events-none px-1 text-base " placeholder="0" value="0" />

                                                    </div>
                                                    <div className='flex justify-end items-center'>
                                                        <div className='bg-black/30 white-text text-base px-2'>0

                                                        </div>
                                                        <div className=' flex justify-center px-2'>
                                                            <button className="placebet-button-disable px-2 py-1 uppercase rounded white-text text-[13px] disabled font-bold">Done</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </>
                                }

                                {/* <div className="pb-20"> */}
                                {betList && betList.length > 0 ?
                                    <div className="space-y-[1px] bg-gray-200 pb-1 rounded">
                                        <MyBetTabBmx
                                            totalProfitLoss={totalProfitLoss} />
                                        <BetListTableBmx betList={betList} />
                                    </div>
                                    : null}
                                <div className=" pb-36 bg-black/30">
                                    <RoundedTabBmx />
                                    <div className="flex space-x-1 justify-end items-center py-0.5 px-2">
                                        {result && result.length > 0 ? result.map((element, index) => (
                                            <div onClick={() => this.handleResultModel(element)} className="bg-[#008000] w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                                                <p className="text-yellow-200 font-normal text-sm">{element && element.result === '2' ? "B" : element && element.result === '1' ? <p className="text-[#FF4502] font-normal text-sm">A</p> : "-"}</p>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                                {/* </div> */}

                            </div>
                        </div>
                    </div >}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { users } = state;
    return {
        users,
    };
}

export default connect(mapStateToProps)(TeenpattiOneDayTheme2);

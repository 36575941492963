import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { BsSuitSpadeFill, BsSuitClubFill } from "react-icons/bs";
import { ImDiamonds } from "react-icons/im";
import { AiFillHeart } from "react-icons/ai";
import io from 'socket.io-client';
import ResultModelBmx from "../../casino_components/ResultModelBmx/ResultModelBmx";
import BetLockedRoundedBmx from "../../casino_components/BetLockedRoundedBmx/BetLockedRoundedBmx";
import FlipCountdown from '@rumess/react-flip-countdown';
import RoundedTabBmx from "../../casino_components/RoundedTabBmx/RoundedTabBmx";
import axios from "axios";
import PageHeaderTheme from "../../casino_components/PageHeaderTheme/PageHeaderTheme";
import Loader from "../../components/Loader/Loader";
import MyBetTabBmx from "../../casino_components/MyBetTabBmx/MyBetTabBmx";
import BetListTableBmx from "../../casino_components/BetListTableBmx/BetListTableBmx";
import moment from "moment";
import { httpPost } from "../../_helpers/http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from '../../components/ErrorTost/ErrorTost';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { betChipsData } from '../../_config';


class AmarAkbarAnthony extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      Result: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "aaa",
      betList: [],
      betresponse: [],
      newCasinoModal: false,
      BetPlaceDesktop: false,
      betFor: null,
      betOpen: false,
      myBetOpen: false,
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      betChipsData: [],
      socketPerm: "",
      domainSettingByDomainName: null,
    };
    this.socket = null;
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  checkWindowWidth() {
    const isMobile = window.matchMedia('(max-width: 100vw)').matches;

    if (isMobile && this.inputRef.current) {
      setTimeout(() => {
        this.inputRef.current.focus();
      }, 0);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  handleBackOpen = (data, sectionRef) => {
    try {
      this.setState({
        backBetModal: false,
      });

      if (this.scrollTimeout) {
        clearInterval(this.scrollTimeout);
      }

      this.setState({
        backBetModal: true,
        betSlipData: { ...data, stake: "0" },
        count: data.odds,
        teamname: data.name,
        time: 7,
      });

      this.scrollTimeout = setInterval(() => {
        this.setState(prevState => ({ time: prevState.time - 1 }), () => {
          if (this.state.time === 0) {
            clearInterval(this.scrollTimeout);
            this.setState({ backBetModal: false });
          }
        });
      }, 1000);

      setTimeout(() => {
        if (sectionRef && sectionRef.current) {
          sectionRef.current.scrollIntoView({ behavior: 'smooth' });

          if (window.innerWidth <= 768 && this.inputRef.current && sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
              this.inputRef.current.focus();
            }, 0);
          }
        }
      }, 0);
    } catch (error) {
      console.error('Error handling back open:', error);
    }
  };


  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.data.sid,
      "rate": this.state.count + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "aaa",
      "eventId": this.props.match.params.eventId,
      "betFor": this.state.betSlipData.betFor + "",
      "isLay": this.state.betSlipData.type === "No" ? true : false,
    }
    // console.log(this.state.betSlipData, "betObjectbetObject");

    const result = await httpPost("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        toast.success(<ErrorTost message={result.message} />, {
          autoClose: 1500,
        });
        this.betList(this.props.match.params.eventId)
        this.props.dispatch(userActions.getUserBalance());
      }
      else {
        toast.error(<ErrorTost message={result.message} />, {
          autoClose: 1500,
        });
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.getDomainSettingByDomainName();
    let requestData = {
      eventId: this.props.match.params.eventId,
    }
    this.getDidMountData(requestData)
    this.betChipData()
    if (this.inputRef.current) {
      this.inputRef.current.addEventListener('touchstart', this.handleTouchStart);
    }
    if (window.innerWidth <= 768 && this.inputRef.current) {
      this.inputRef.current.focus();
      this.inputRef.current.dispatchEvent(new Event('touchstart'));
    }
  }

  getDomainSettingByDomainName = () => {
    try {
      let domainSetting = localStorage.getItem('domainSettingByDomainName');
      if (domainSetting) {
        const parsedDomainSetting = JSON.parse(domainSetting);
        this.setState({ domainSettingByDomainName: parsedDomainSetting });
      } else {
        this.setState({ domainSettingByDomainName: null });
      }
    } catch {
      console.error('Error parsing domainSettingByDomainName:');
      this.setState({ domainSettingByDomainName: null });
    }
  };

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        socketPerm: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : "",
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails.eventId ? casinoDetails.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "aaa",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails.eventId) {
        this.betList(casinoDetails.eventId)
      }

      let socketPerm = ""
      let socketUrl = ""
      let cacheUrl = ""
      if (casinoDetails.fetchData) {
        socketPerm = casinoDetails.fetchData
      }
      if (casinoDetails.socketURL) {
        socketUrl = casinoDetails.socketURL
      }
      if (casinoDetails.cacheURL) {
        cacheUrl = casinoDetails.cacheURL
      }

      if (socketPerm === "socket") {
        await this.connectSocket(socketUrl, socketPerm, casinoDetails.shortName)
      } else {
        await this.callCache(cacheUrl);
      }
    } else {

    }
    this.setState({ showLoader: false })
  }

  connectSocket = async (socketUrl, socketPerm, shortName) => {
    if (!socketUrl) {
      socketUrl = this.state.socketURL
    }
    if (!socketPerm) {
      socketPerm = this.state.socketPerm
    }
    if (!shortName) {
      shortName = this.state.shortName
    }
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      return;
    }

    if (socketPerm === "socket") {
      this.socket = io.connect(socketUrl, {
        transports: ["websocket"],
        reconnection: false,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99,
      });

      this.socket.emit('JoinRoom', shortName);
      this.socket.on(shortName, data => {
        this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
        this.setState({ casinoData: data })
        this.updatePos()
      });
    }
  }

  callCache = async (cacheUrl) => {
    try {
      this.interval = setInterval(async () => this.getMarketCacheUrl(cacheUrl), 1000);
    } catch (error) {
      console.error('Error calling cache:', error);
    }
  }

  getMarketCacheUrl = async (cacheUrl) => {
    try {
      const response = await axios.get(cacheUrl);
      this.setState({ casinoData: response?.data?.data })
    } catch (error) {
      console.error('Error fetching cache data:', error);
    }
  }


  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets && filteredBets.length > 0 && filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }

  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }


  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
    this.cleanupWebSocket();
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }

  cleanupWebSocket = () => {
    if (this.socket) {
      this.socket.close();
    }
  };

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && !this.state.isConnected) {
      this.connectSocket()
    } else if (document.visibilityState === 'hidden') {
      this.cleanupWebSocket();
    }
  };

  betChipData = () => {
    try {
      let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
      const myArray = betChipsDatas && Object.keys(betChipsDatas).length > 0 ? Object.values(betChipsDatas) : Object.values(betChipsData);
      this.setState({ betChipsData: myArray });
    } catch (error) {
      console.error('Error parsing JSON from localStorage: betChipsDatas', error);
      this.setState({ betChipsData: betChipsData });
    }
  };


  handleClose = () => {
    this.setState({ backBetModal: false, backBetModalMobile: false });
  };

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  render() {
    // let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, betChipsData, domainSettingByDomainName } = this.state;
    let { data, result } = casinoData ? casinoData : {};
    let Amar = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    let Akbar = data && data.t2 && data.t2[1] ? data.t2[1] : {};
    let Anthony = data && data.t2 && data.t2[2] ? data.t2[2] : {};
    let Even = data && data.t2 && data.t2[3] ? data.t2[3] : {};
    let Odd = data && data.t2 && data.t2[4] ? data.t2[4] : {};
    let Red = data && data.t2 && data.t2[5] ? data.t2[5] : {};
    let Black = data && data.t2 && data.t2[6] ? data.t2[6] : {};
    let CardA = data && data.t2 && data.t2[7] ? data.t2[7] : {};
    let Card2 = data && data.t2 && data.t2[8] ? data.t2[8] : {};
    let Card3 = data && data.t2 && data.t2[9] ? data.t2[9] : {};
    let Card4 = data && data.t2 && data.t2[10] ? data.t2[10] : {};
    let Card5 = data && data.t2 && data.t2[11] ? data.t2[11] : {};
    let Card6 = data && data.t2 && data.t2[12] ? data.t2[12] : {};
    let Card7 = data && data.t2 && data.t2[13] ? data.t2[13] : {};
    let Card8 = data && data.t2 && data.t2[14] ? data.t2[14] : {};
    let Card9 = data && data.t2 && data.t2[15] ? data.t2[15] : {};
    let Card10 = data && data.t2 && data.t2[16] ? data.t2[16] : {};
    let CardJ = data && data.t2 && data.t2[17] ? data.t2[17] : {};
    let CardQ = data && data.t2 && data.t2[18] ? data.t2[18] : {};
    let CardK = data && data.t2 && data.t2[19] ? data.t2[19] : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};

    return (
      <>
        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`overflow-auto h-screen scroll-smooth  ${domainSettingByDomainName && domainSettingByDomainName.clientNotification ? "md:pt-[105px] pt-[100px]" : "md:pt-[72px] pt-[70px]"}`}>
            {ResultModel &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Amar Akbar Anthony"}
                shortName={shortName ? shortName : "aaa"}
                result={this.state.result}
              />
            ) : null}
            {/* {LoadingBet === true ?
              <>
                <PlaceBetMobileBmxBet
                  betSlipData={this.state.betSlipData}
                  time={time}
                  count={count}
                />
              </> :
              <>
                {backBetModal && backBetModal === true ? (
                  <PlaceBetMobileBmx
                    betSlipData={this.state.betSlipData}
                    updateStackOnclic={this.updateStackOnclic}
                    placeBet={this.placeBet}
                    handleClose={this.handleClose}
                    section1Ref={this.section1Ref}
                    time={this.state.time}
                    inputChange={this.inputChange}
                    decreaseCount={this.decreaseCount}
                    increaseCount={this.increaseCount}
                    count={this.state.count}
                  />
                ) : null}
              </>
            } */}

            <div className="lg:flex w-full h-screen p-1.5 space-x-1.5">
              <div className="lg:w-[41%] md:w-full mx-auto">
                <PageHeaderTheme
                  PageTitle={name ? name : "AMAR AKBAR ANTHONY"}
                  ruleImage={"/rulesImage/aaaRules.webp"}
                  t1={t1}
                />
                <div className="bg-black flex justify-between w-full relative xl:h-[400px] md:h-[350px] h-[250px]">
                  <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                  <div className=" flex justify-between">
                    <div className="absolute top-0 left-0">
                      <div className="flex justify-start space-x-1 p-2">
                        <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="lg:h-12 h-9 lg:w-9 w-7" />
                      </div>
                    </div>
                    <div className="flex justify-end items-end absolute md:bottom-2 bottom-2 right-0 ">
                      <FlipCountdown
                        titlePosition='hidden'
                        hideYear
                        hideMonth
                        hideDay
                        hideHour
                        hideMinute
                        endAtZero
                        size='small'
                        endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : null)).toUTCString()}
                      />
                    </div>
                  </div>
                </div>
                <div className=" py-1">
                  <div className="odds-bet ">
                    <div className="grid grid-cols-3 space-x-2 ">
                      <div className=" pb-3 pt-1">
                        <div className="flex justify-center items-center ">
                          <p className="text-[16px] font-semibold" >
                            <span>A.</span> {Amar && Amar.nat && Amar.nat ? Amar.nat : "Amar"}</p>
                        </div>

                        <div className="flex justify-center items-center">
                          <div className="border match_bg_blue_index-0 w-full text-center py-2 relative cursor-pointer" onClick={() => this.handleBackOpen({ data: Amar, type: "Yes", odds: Number.parseFloat(Math.abs(Number(Amar.b1) - Number(oddsDifference))).toFixed(2), nat: Amar.nat, betFor: "aaa" }, this.section1Ref)}>
                            <div className="text-[16px] font-[400] leading-4">{Amar && Amar.b1 && Amar.b1 ? Number.parseFloat(Math.abs(Number(Amar.b1) - Number(oddsDifference))).toFixed(2) : "0.00"}</div>
                            {Amar.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div onClick={() => this.handleBackOpen({ data: Amar, type: "No", odds: Amar.l1, nat: Amar.nat, betFor: "aaa" }, this.section1Ref)} className="border match_bg_pink_index-0 w-full text-center py-2 cursor-pointer relative">
                            <div className="text-[16px] font-[400] leading-4">{Amar && Amar.l1 && Amar.l1 ? Amar.l1 : "0.00"}</div>
                            {Amar.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                        </div>
                        <div className={`${posArray[Amar.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Amar.sid] ?? 0.00}</div>
                      </div>
                      <div className=" pb-3 pt-1">
                        <div className="flex justify-center items-center ">
                          <p className="text-[16px] font-semibold">
                            <span>B.</span> {Akbar && Akbar.nat && Akbar.nat ? Akbar.nat : "Akbar"}</p>
                        </div>
                        <div className="flex justify-center items-center">
                          <div className="border match_bg_blue_index-0 w-full text-center py-2 relative cursor-pointer"
                            onClick={() => this.handleBackOpen({ data: Akbar, type: "Yes", odds: Number.parseFloat(Math.abs(Number(Akbar.b1) - Number(oddsDifference))).toFixed(2), nat: Akbar.nat, betFor: "aaa" }, this.section1Ref)}>
                            <div className="text-[16px] font-[400] leading-4">{Akbar && Akbar.b1 && Akbar.b1 ? Number.parseFloat(Math.abs(Number(Akbar.b1) - Number(oddsDifference))).toFixed(2) : "0.00"}</div>
                            {Akbar.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className="border match_bg_pink_index-0 w-full text-center py-2 cursor-pointer relative"
                            onClick={() => this.handleBackOpen({ data: Akbar, type: "No", odds: Akbar.l1, nat: Akbar.nat, betFor: "aaa" }, this.section1Ref)}>
                            <div className="text-[16px] font-[400] leading-4">{Akbar && Akbar.l1 && Akbar.l1 ? Akbar.l1 : "0.00"}</div>
                            {Akbar.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                        </div>
                        <div className={`${posArray[Akbar.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Akbar.sid] ?? 0.00}</div>
                      </div>
                      <div className=" pb-3 pt-1">
                        <div className="flex justify-center items-center ">
                          <p className="text-[16px] font-semibold">
                            <span>C.</span>{Anthony && Anthony.nat && Anthony.nat ? Anthony.nat : "Anthony"}</p>
                        </div>
                        <div className="flex justify-center items-center">
                          <div className="border match_bg_blue_index-0 w-full text-center py-2 cursor-pointer relative" onClick={() => this.handleBackOpen(
                            { data: Anthony, type: "Yes", odds: Anthony.b1, nat: Number.parseFloat(Math.abs(Number(Anthony.b1) - Number(oddsDifference))).toFixed(2), betFor: "aaa" }, this.section1Ref)}>
                            <div className="text-[16px] font-[400] leading-4">{Anthony && Anthony.b1 && Anthony.b1 ? Number.parseFloat(Math.abs(Number(Anthony.b1) - Number(oddsDifference))).toFixed(2) : "0.00"}</div>
                            {Anthony.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className="border match_bg_pink_index-0 w-full text-center py-2 cursor-pointer relative" onClick={() => this.handleBackOpen(
                            { data: Anthony, type: "No", odds: Anthony.l1, nat: Anthony.nat, betFor: "aaa" }, this.section1Ref
                          )}>
                            <div className="text-[16px] font-[400] leading-4">{Anthony && Anthony.l1 && Anthony.l1 ? Anthony.l1 : "0.00"}</div>
                            {Anthony.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                        </div>
                        <div className={`${posArray[Anthony.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Anthony.sid] ?? 0.00}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-4  gap-2 py-2 odds-bet">

                  <div className=" px-4 pb-3 pt-1">
                    <div className="flex justify-center items-center ">
                      <p className="text-[16px] font-normal">{Even && Even.b1 ? Even.b1 : "0.00"}</p>
                    </div>
                    <div className="relative">
                      <div className="even-background  w-full text-center py-2.5" onClick={() => this.handleBackOpen({ data: Even, type: "Yes", odds: Even.b1, nat: Even.nat, betFor: "oddEven" }, this.section1Ref)}>
                        <div className="text-[16px] font-bold leading-4 text-white">{Even && Even.nat ? Even.nat : "Even"}</div>
                      </div>
                      {Even && Even.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                    </div>
                    <div className={`${posArray[Even.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Even.sid] ?? 0.00}</div>
                  </div>
                  <div className=" px-4 pb-3 pt-1">
                    <div className="flex justify-center items-center ">
                      <p className="text-[16px] font-normal">{Odd && Odd.b1 ? Odd.b1 : "0.00"}</p>
                    </div>
                    <div className="relative">
                      <div className="even-background  w-full text-center py-2.5"
                        onClick={() => this.handleBackOpen({ data: Odd, type: "Yes", odds: Odd.b1, nat: Odd.nat, betFor: "oddEven" }, this.section1Ref)}>
                        <div className="text-[16px] font-bold leading-4 text-white">{Odd && Odd.nat ? Odd.nat : "Odd"}</div>
                      </div>
                      {Odd && Odd.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                    </div>
                    <div className={`${posArray[Odd.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Odd.sid] ?? 0.00}</div>
                  </div>


                  <div className=" px-4 pb-3 pt-1">
                    <div className="flex justify-center items-center ">
                      <p className="text-[16px] font-normal">{Red && Red.b1 ? Red.b1 : "0.00"}</p>
                    </div>
                    <div className="relative">
                      <div class=" even-background py-2.5 rounded flex  justify-center items-center cursor-pointer space-x-0.5"
                        onClick={() => this.handleBackOpen({ data: Red, type: "Yes", odds: Red.b1, nat: Red.nat, betFor: "color" }, this.section1Ref)}>
                        <span className="text-[#FE0000]">
                          <AiFillHeart size={16} />
                        </span>
                        <span className="text-[#FE0000]">
                          <ImDiamonds size={16} />
                        </span>
                      </div>
                      {Red && Red.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                    </div>
                    <div className={`${posArray[Red.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Red.sid] ?? 0.00}</div>
                  </div>

                  <div className=" px-4 pb-3 pt-1">
                    <div className="flex justify-center items-center ">
                      <p className="text-[16px] font-normal">{Black && Black.b1 ? Black.b1 : "0.00"}</p>
                    </div>
                    <div className="relative">
                      <div class=" even-background py-2.5 rounded flex  justify-center items-center cursor-pointer space-x-0.5"
                        onClick={() => this.handleBackOpen({ data: Black, type: "Yes", odds: Black.b1, nat: Black.nat, betFor: "color" }, this.section1Ref)}>
                        <span className="text-[#000000]">
                          <BsSuitSpadeFill size={16} />
                        </span>
                        <span className="text-[#000000]">
                          <BsSuitClubFill size={16} />
                        </span>
                      </div>
                      {Black && Black.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                    </div>
                    <div className={`${posArray[Black.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Black.sid] ?? 0.00}</div>
                  </div>


                </div>
                <div className="py-1">
                  <div className="odds-bet">
                    <p className="flex justify-center items-center text-black text-[16px] py-2 font-bold">11</p>
                    <div class="w-full grid lg:grid-cols-12 grid-cols-6 md:space-x-2 space-x-0 text-center ">
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: CardA, type: "Yes", odds: CardA.b1, nat: CardA.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/1.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {CardA.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[CardA.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[CardA.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center"
                            onClick={() => this.handleBackOpen({ data: Card2, type: "Yes", odds: Card2.b1, nat: Card2.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/2.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {Card2.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[Card2.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card2.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: Card3, type: "Yes", odds: Card3.b1, nat: Card3.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/3.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {Card3.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[Card3.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card3.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: Card4, type: "Yes", odds: Card4.b1, nat: Card4.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/3.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {Card4.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[Card4.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card4.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: Card5, type: "Yes", odds: Card5.b1, nat: Card5.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/5.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {Card5.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[Card5.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card5.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: Card6, type: "Yes", odds: Card6.b1, nat: Card6.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/6.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {Card6.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[Card6.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card6.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: Card7, type: "Yes", odds: Card7.b1, nat: Card7.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/7.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {Card7.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[Card7.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card7.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: Card8, type: "Yes", odds: Card8.b1, nat: Card8.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/8.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {Card8.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[Card8.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card8.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: Card9, type: "Yes", odds: Card9.b1, nat: Card9.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/9.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {Card9.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[Card9.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card9.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: Card10, type: "Yes", odds: Card10.b1, nat: Card10.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/10.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {Card10.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[Card10.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card10.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: CardJ, type: "Yes", odds: CardJ.b1, nat: CardJ.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/11.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {CardJ.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[CardJ.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[CardJ.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: CardQ, type: "Yes", odds: CardQ.b1, nat: CardQ.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/12.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {CardQ.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[CardQ.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[CardQ.sid] ?? 0.00}</p>
                      </span>
                      <span className="">
                        <div className="relative">
                          <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                            { data: CardK, type: "Yes", odds: CardK.b1, nat: CardK.nat, betFor: "cards" }, this.section1Ref)}>
                            <img src="/assets/images/13.jpg" alt="" className="w-12 h-14" />
                          </div>
                          {CardK.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                        </div>
                        <p className={`${posArray[CardK.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[CardK.sid] ?? 0.00}</p>
                      </span>
                    </div>
                  </div>
                </div>

                {LoadingBet === true ?
                  <div className='border-[2px] border-[#C99D1E] bg-[#f9f9f9] p-[5px]  lg:block hidden rounded-[5px]' >
                    <div className="">
                      <div className='flex justify-between items-center'>
                        <div className="text-black text-[20px] font-bold text-center w-full whitespace-nowrap">{this.state.betFor && this.state.betFor ? this.state.betFor : null} :</div>
                        <span className='w-full text-left'>
                          RATE : {this.state.count}
                        </span>
                        <div className='bg-black rounded-full white-text p-2'>
                          <CountdownCircleTimer
                            isPlaying
                            duration={7}
                            colors={['#000000']}
                            colorsTime={[7]}
                            size={20}
                            strokeWidth={0}
                            className="bg-white p-0"
                          >
                            {({ remainingTime }) => remainingTime === 7 ? time : time}

                          </CountdownCircleTimer>
                        </div>
                      </div>

                      <div className='flex justify-center items-center space-x-2'><span>AMOUNT</span>
                        <input ref={this.inputRef} type="number" className="px-1 w-24 border-2 border-black" placeholder="0" />
                        <div className='relative flex justify-center'>
                          <button className="bg-[#3A61A2] px-3 py-1 uppercase rounded-full text-white text-[13px] tracking-widest flex justify-center items-center">Done
                            <button className="w-full white-text px-3 py-2 h-4 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                              <div className=" flex items-center justify-center absolute bg-transparent">
                                <div className="flex items-center justify-center bg-transparent w-full">
                                  <div className="w-5 h-5 rounded-full animate-spin 
                          border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                </div>
                              </div>
                            </button>
                          </button>
                        </div>
                      </div>

                    </div>
                    <div className='flex justify-center items-center'>
                      <div className='row '>
                        {betChipsData && betChipsData.length > 0 ? betChipsData.map((element, index) => (
                          <div key={index} className="col-lg-3 col-md-2 col-4 py-2 ">
                            <span onClick={() => this.updateStackOnclick(element)} className='bg-[#61ACDE] p-1.5 px-4 text-white md:font-bold font-medium rounded-md cursor-pointer' key={index}>{element}</span>
                          </div>
                        )) : null}

                      </div>
                    </div>
                    <div className='flex justify-between items-center space-x-1'>
                      <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" >Clear Input</button>
                      <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.handleClose()}>Clear Close</button>
                    </div>
                  </div>
                  :
                  <>
                    {backBetModal === true ?
                      <div className='py-1' ref={this.section1Ref} id="section1" >
                        <div className='border-[2px] border-[#C99D1E] bg-[#f9f9f9] p-[5px]  lg:block hidden rounded-[5px]' >
                          <div className="">
                            <div className='flex justify-between items-center'>
                              <div className="text-black text-[20px] font-bold text-center w-full whitespace-nowrap">{this.state.betSlipData && this.state.betSlipData.nat ? this.state.betSlipData.nat : null} :</div>
                              <span className='w-full text-left'>
                                RATE : {Number(this.state.betSlipData.rate)}
                              </span>
                              <div className='bg-black rounded-full white-text p-2'>
                                <CountdownCircleTimer
                                  isPlaying
                                  duration={7}
                                  colors={['#000000']}
                                  colorsTime={[7]}
                                  size={20}
                                  strokeWidth={0}
                                  className="bg-white p-0"
                                >
                                  {({ remainingTime }) => remainingTime === 7 ? 7 : time}

                                </CountdownCircleTimer>
                              </div>
                            </div>

                            <div className='flex justify-center items-center space-x-2'><span>AMOUNT</span>
                              <input ref={this.inputRef} type="number" className="px-1 w-24 border-2 border-black" placeholder="0" value={this.state.betSlipData.stake} onChange={this.updateStake} />
                              <div className=' flex justify-center'>
                                <button onClick={() => this.placeBet()} className="bg-[#3A61A2] px-5 py-1 uppercase rounded-full text-white text-[13px] tracking-widest">Done
                                </button>
                              </div>
                            </div>

                          </div>
                          <div className='flex justify-center items-center'>
                            <div className='row '>
                              {betChipsData && betChipsData.length > 0 ? betChipsData.map((element, index) => (
                                <div key={index} className="col-lg-3 col-md-2 col-4 py-2 ">
                                  <span onClick={() => this.updateStackOnclick(element)} className='bg-[#61ACDE] p-1.5 px-4 text-white md:font-bold font-medium rounded-md cursor-pointer' key={index}>{element}</span>
                                </div>
                              )) : null}

                            </div>
                          </div>
                          <div className='flex justify-between items-center space-x-1'>
                            <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.updateStackOnclick("0")}>Clear Input</button>
                            <button className="bg-red-700 text-white text-[15px] rounded-[2px] h-[30px] text-center w-full px-2" onClick={() => this.handleClose()}>Clear Close</button>
                          </div>
                        </div>
                      </div>
                      : null}
                  </>}

                {LoadingBet === true ?
                  <>
                    <div className='block lg:hidden bg-[#E9E9E9]'>
                      <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                        <div className='text-sm text-black/30 font-bold pl-2'>Amount</div>
                        <div className='border-[1px] border-gray-400 w-1/2'>
                          <input type="number" className=" w-full text-black/30 bg-[#E9E9E9] focus:outline-none pointer-events-none px-1 text-base " placeholder="0" value="0" />

                        </div>
                        <div className='flex justify-end items-center'>
                          <div className='bg-black white-text px-2'>
                            <CountdownCircleTimer
                              isPlaying
                              duration={7}
                              colors={['#000000']}
                              colorsTime={[7]}
                              size={20}
                              strokeWidth={0}
                              className="bg-white p-0"

                            >
                              {({ remainingTime }) => remainingTime === 7 ? time : time}
                            </CountdownCircleTimer>
                          </div>
                          <div className='relative flex justify-center px-2'>
                            <button className="placebet-button-disable px-2 py-1 uppercase rounded white-text text-[13px] disabled font-bold flex justify-center items-center">Done
                              <button className="w-full white-text px-3 py-2 h-4 flex justify-center items-center space-x-1 cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                <div className=" flex items-center justify-center absolute bg-transparent">
                                  <div className="flex items-center justify-center bg-transparent w-full">
                                    <div className="w-5 h-5 rounded-full animate-spin 
                          border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                  </div>
                                </div>
                              </button>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    {backBetModal === true ?
                      <div ref={this.section1Ref} id="section1">
                        <div className='lg:hidden block bg-[#E9E9E9]' >
                          <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                            <div className='text-sm font-bold pl-2'>Amount</div>
                            <div className='border-[1px] border-gray-400 w-1/2'>
                              <input ref={this.inputRef} type="text" autoFocus  // or type="text"
                                autoCapitalize="none" className=" w-full black-text bg-[#E9E9E9] text-base focus:outline-none cursor-pointer placeholder-black px-1" placeholder="0" name="stake" onChange={this.inputChange} list="stake" inputMode="numeric" />
                              <datalist id="stake" className='bg-black white-text'>
                                {betChipsData.map((element, index) => (
                                  <option key={index} value={element ? element : 0} />
                                ))}
                              </datalist>
                            </div>

                            <div className='flex justify-end items-center text-base'>
                              <div className='bg-black white-text px-2'>
                                <CountdownCircleTimer
                                  isPlaying
                                  duration={7}
                                  colors={['#000000']}
                                  colorsTime={[7]}
                                  size={20}
                                  strokeWidth={0}
                                  className="bg-white p-0"

                                >
                                  {({ remainingTime }) => remainingTime === 7 ? 7 : time}
                                </CountdownCircleTimer>
                              </div>
                              <div className=' flex justify-center px-2'>
                                <button onClick={() => this.placeBet()} className="placebet-button px-2 py-1 uppercase rounded white-text font-bold text-[13px] cursor-pointer">Done</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> : <div className='block lg:hidden bg-[#E9E9E9]'>
                        <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4'>
                          <div className='text-sm text-black/30 font-bold pl-2'>Amount</div>
                          <div className='border-[1px] border-gray-400 w-1/2'>
                            <input type="number" className=" w-full text-black/30 bg-[#E9E9E9] focus:outline-none pointer-events-none px-1 text-base " placeholder="0" value="0" />

                          </div>
                          <div className='flex justify-end items-center'>
                            <div className='bg-black/30 white-text text-base px-2'>0

                            </div>
                            <div className=' flex justify-center px-2'>
                              <button className="placebet-button-disable px-2 py-1 uppercase rounded white-text text-[13px] disabled font-bold">Done</button>
                            </div>
                          </div>
                        </div>
                      </div>}
                  </>
                }

                {betList && betList.length > 0 ?
                  <div className="space-y-[1px] bg-gray-200 pb-1 rounded">
                    <MyBetTabBmx
                      totalProfitLoss={totalProfitLoss} />
                    <BetListTableBmx betList={betList} />
                  </div>
                  : null}
                <div className="pb-36 bg-black/30">
                  <RoundedTabBmx />
                  <div className="flex space-x-1 justify-end items-center py-0.5 px-2 ">
                    {result && result.length > 0 ? result.map((element, index) => (
                      <div onClick={() => this.handleResultModel(element)} className="bg-[#008000]  w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                        <p className={`${element && element.result && element.result === "1" ? "text-[#F75500]" : element && element.result && element.result === "2" ? "text-[#FFF523]" : "text-[#FFFF2E]"} font-normal text-sm`}>{element && element.result && element.result === "1" ? "A" : element && element.result && element.result === "2" ? "B" : element && element.result && element.result === "3" ? "C" : "-"}</p>
                      </div>
                    )) : null}
                  </div>
                </div>
              </div>


            </div>
          </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(AmarAkbarAnthony);

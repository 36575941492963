
import React, { useState, useEffect } from "react";
import { httpPost } from "../../_helpers/http";
import Loader from "../../components/Loader/Loader";
import { useHistory } from 'react-router-dom';


const IframeAviator = () => {

    const [casinoData, setCasinoData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorType, setErrorType] = useState(0);
    const [resMessage, setResMessage] = useState("");
    const history = useHistory();
    useEffect(() => {
        getCasinoData();

        if (showAlert) {
            const timeout = setTimeout(() => {
                setShowAlert(false);
                history.push('/app/dashboard');
            }, 2000);

            // Cleanup function to clear timeout when component unmounts or showAlert changes
            return () => clearTimeout(timeout);
        }
    }, [showAlert]);


    const handleIframeLoad = () => {
        setLoading(false);
    };

    const getCasinoData = async () => {

        try {
            let casinoLogin = {
                "gameId": "201206",
                "platformId": "mobile",
                "redirectUrl": "https://antpro99.net/#/app/dashboard",
            };

            let casinoLoginResponse = await httpPost('user/casinoLoginUrl', casinoLogin);
            if (!casinoLoginResponse.error) {
                setLoading(true);
                setCasinoData(casinoLoginResponse && casinoLoginResponse.data ? casinoLoginResponse.data : {});
                setLoading(false);
            } else {
                setShowAlert(true);
                setErrorType(1);
                setResMessage(casinoLoginResponse.message ? casinoLoginResponse.message : "Something went wrong");
            }

        } catch (error) {
            console.error("Error fetching casino data:", error);
            setShowAlert(true);
            setErrorType(1);
            setResMessage("Something went wrong");

            // Handle error, show a user-friendly message, etc.
            // Set showModal to true in case of an error
        }

    };


    // console.log("casinoDatacasinoDatacasinoDatacasinoData", casinoData);

    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    return (
        <div className={`min-h-screen ${clientNotification && clientNotification.length > 0 ? "md:pt-[105px] pt-[100px]" : "md:pt-[82px] pt-[80px]"}`}>
            {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 z-30 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='white-text font-bold'>{resMessage}</span></div>}
            {loading === true ?
                <Loader />
                : null}

            {casinoData ? (
                <>
                    {loading === true ?
                        <Loader />
                        : null}
                    <div className="overflow-y-auto h-screen ">
                        <iframe src={casinoData.url} title=" " loading='lazy' className=" mx-auto w-[100%] h-full pb-20" onLoad={handleIframeLoad} />
                    </div>
                </>

            ) : <Loader />
            }

        </div>
    );
};

export default IframeAviator;
